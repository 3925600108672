import React from 'react';
import Layout from '../components/Layout';
import AboutSlides from '../components/aboutSlideshow';

const About = () => (
  <div>
    <Layout>
    <div className={"slideshow"}><AboutSlides /></div>
    <ul>
      <li>
        <h1>History</h1>
        <h4>Chetwynd Tennis Club began its operations in the period 1932 
        and was lead by Honoray Members Mr. & Mrs.Clyde Weatherhead and 
        Mr.La Roque. It was learnt that the original intent of the club 
        was to provide tennis, volleyball and netball for its membership. 
        The leadership also assisted with the development of young players 
        such as Dave James, Carlton Munroe, Majorie Thomas, Clem Bostic 
        and many others. </h4>
        <h4> The records will show that during the 90’ s Chetwynd Tennis 
        Club went on to produce a national tennis champion by the name of 
        Kenny Boodoosing.The Club also at the time had in its membership 
        a list of ‘A’ players like Prakash Maharaj and Dale Boyce. </h4>
        <h4> The Club’ s Past Presidents from 1980 to present includes: 
        Dave James, Hurbert Bailey, Ingrid Baptiste, Jean Ganville, 
        Mickey Lamont, Fred Williams, Steve Thomas, Phillip Robinson, 
        Mc Colin Fontenelle and Sarah Salandy. Two members, Dave and 
        Earle James, went on to be Presidents of Trinidad and Tobago 
        Tennis Association. All these individuals helped to propel the 
        club forward, leading to its current position. </h4>
      </li>
    </ul>
    </Layout>
  </div>
);

export default About;
